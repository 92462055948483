import authService from "../common/auth-service"
import { AppConstants } from "../shared/appConstant";


export const getHeaders = () => {
    let token = authService.getAuthToken();
    let authType =authService.getAuthType();
    return {
        headers : { 
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + token,
            'AuthType': authType,
          }
    };
}


export const ApiRoutes = {
    commonApi:{
        GetOrganizationOptions: `${AppConstants.API_URL}/api/Common/GetOrganizationOptions`,
        OrganisationCompanies: `${AppConstants.API_URL}/api/Common/GetCompanies`,
        OrganisationProfitCenters: `${AppConstants.API_URL}/api/Common/GetProfitCenters`,
        OrganisationBusinessAreas: `${AppConstants.API_URL}/api/Common/GetBusinessAreas`,
        FetchOrganizationYear: `${AppConstants.API_URL}/api/Common/getOrganizationYear`,
        GetCompanyDivisions: `${AppConstants.API_URL}/api/Common/GetCompanyDivisions`,
        GetRepresentatives: `${AppConstants.API_URL}/api/Common/GetRepresentatives`,
        GetActivityTypes: `${AppConstants.API_URL}/api/Common/GetActivityTypes`,
        GetCustomerTypes: `${AppConstants.API_URL}/api/Common/GetCustomerTypes`,
        GetResourceCategories: `${AppConstants.API_URL}/api/Common/GetResourceCategories`,
    },
    userBasedPagesApi:{
        getUserBasedPages: `${AppConstants.API_URL}/api/page/userpages`,
    },
    basicAuthApi: {
        BasicAuthLogin: `${AppConstants.API_URL}/api/BasicAuth/BasicAuthLogin`,
        ChangePassword: `${AppConstants.API_URL}/api/User/ChangePassword`,
    },
    pageApi: {
        fetchPage: `${AppConstants.API_URL}/api/Page/getPage`,
        pageApiPath: `${AppConstants.API_URL}/api/Page`,
    },
    roleApi: {
        fetchRoles: `${AppConstants.API_URL}/api/Role/getrole`,
        fetchPage: `${AppConstants.API_URL}/api/Page/getPage`,
        rolePageApiPath: `${AppConstants.API_URL}/api/Page/RolePage`,
    },
    userApi: {
        fetchRoles: `${AppConstants.API_URL}/api/role/getrole`,
        fetchAzureADUser: `${AppConstants.API_URL}/api/user/GetUsersFromAZureAD`,
        userApiPath: `${AppConstants.API_URL}/api/user`,
        fetchUser: `${AppConstants.API_URL}/api/user/getuser`
    },
    permissionScopeApi: {
        fetchPermissionScope: `${AppConstants.API_URL}/api/PermissionScope/getPermissionScope`,
        PermissionScopeApiPath: `${AppConstants.API_URL}/api/PermissionScope`,
    },
    costCenterPermissionApi: {
        fetchProfitCenter: `${AppConstants.API_URL}/api/CostCenterPermission/getProfitCenter`, 
        fetchCostCenterPermission: `${AppConstants.API_URL}/api/CostCenterPermission/getCostCenterPermission`,
        costCenterPermissionApiPath: `${AppConstants.API_URL}/api/CostCenterPermission`,
        deleteCostCenterPermission: `${AppConstants.API_URL}/api/CostCenterPermission/DeleteCostCenterPermission`,
    },
    organizationApi: {
        fetchOrganization: `${AppConstants.API_URL}/api/Organization/getOrganization`,
        OrganizationApiPath: `${AppConstants.API_URL}/api/Organization`,
    },
    organisationMappingApi: {
        getAllOrganisationMappings: `${AppConstants.API_URL}/api/OrganisationMapping/GetOrganisationMappings`,
        OrganisationMapping: `${AppConstants.API_URL}/api/OrganisationMapping`,
        OrganisationMappingCompanies: `${AppConstants.API_URL}/api/OrganisationMapping/GetCompanies`, //tofixerror
        OrganisationMappingProfitCenters: `${AppConstants.API_URL}/api/OrganisationMapping/GetProfitCenters`, //tofixerror
        OrganisationMappingBusinessAreas: `${AppConstants.API_URL}/api/OrganisationMapping/GetBusinessAreas`, //tofixerror
    },
    salesBudgetApi:{
        getSalesDivisionBudget: `${AppConstants.API_URL}/api/SalesBudget/GetSalesDivisionBudget`,
        getSalesRepBudget: `${AppConstants.API_URL}/api/SalesBudget/GetSalesRepBudget`,
        postSalesDivisionBudget: `${AppConstants.API_URL}/api/SalesBudget/PostSalesDivisionBudget`,
        postSalesRepBudget: `${AppConstants.API_URL}/api/SalesBudget/PostSalesRepBudget`,
    },
    salesActivitiesApi:{
        getSalesActivities: `${AppConstants.API_URL}/api/SalesActivities/GetSalesActivities`,
        postSalesActivities: `${AppConstants.API_URL}/api/SalesActivities/PostSalesActivities`,
    },
    salesStageProbabilitiesApi:{
        getSalesStageProbabilities: `${AppConstants.API_URL}/api/SalesStageProbabilities/GetSalesStageProbabilities`,
        postSalesStageProbabilities: `${AppConstants.API_URL}/api/SalesStageProbabilities/PostSalesStageProbabilities`,
    },
    timeCodesApi:{
        getTimeCodes: `${AppConstants.API_URL}/api/TimeCodes/GetTimeCodes`,
        postTimeCodes: `${AppConstants.API_URL}/api/TimeCodes/PostTimeCodes`,
    },
    paycodesApi:{
        getPaycodes: `${AppConstants.API_URL}/api/Paycodes/GetPaycodes`,
        postPaycodes: `${AppConstants.API_URL}/api/Paycodes/PostPaycodes`,
    },
    roleMappingPropertiesApi: {
        fetchRoleMappingPropertyValues: `${AppConstants.API_URL}/api/RoleMappingProperties/GetRoleMappingPropertyValues`,
        fetchRoleMappingProperties: `${AppConstants.API_URL}/api/RoleMappingProperties/getRoleMappingProperties`,
        RoleMappingPropertiesApiPath: `${AppConstants.API_URL}/api/RoleMappingProperties`,
        RoleMappingPropertyDeleteApiPath: `${AppConstants.API_URL}/api/RoleMappingProperties/Delete`,
        RoleMappingPropertyValueDeleteApiPath: `${AppConstants.API_URL}/api/RoleMappingProperties/DeleteValue`,
    },
    roleMappingsApi:{
        getRoleMappings: `${AppConstants.API_URL}/api/RoleMappingValues/GetRoleMappingValues`,
        postRoleMappings: `${AppConstants.API_URL}/api/RoleMappingValues/PostRoleMappingValues`,
    },
    vehicleEfficiencyApi:{
        getVehicleEfficiency: `${AppConstants.API_URL}/api/VehicleEfficiency/GetVehicleEfficiency`,
        postVehicleEfficiency: `${AppConstants.API_URL}/api/VehicleEfficiency/PostVehicleEfficiency`,
    },
    resourceMappingApi: {
        getAllResourceMappings: `${AppConstants.API_URL}/api/ResourceMapping/GetResourceMappings`,
        ResourceMapping: `${AppConstants.API_URL}/api/ResourceMapping`,
    },
}

